.spinner {
  height: 28px; /* h-4 = 34px */
  width: 28px; /* w-4 = 34px */
  border-radius: 50%; /* rounded-full */
  border: 2px solid #d1d5db; /* border-2 border-gray-300 */
  border-top: 2px solid white; /* border-t-white */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
