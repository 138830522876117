.error-state {
  background-color: rgb(189, 0, 0);
  color: white;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  text-align: center;
  border: 2px solid red;
  height: 60%;
  width: 98%;
  position: relative;
  top: -80px;
  margin: auto;
}
.error-span {
  display: block;
  padding: 0.5rem;
  font-size: 1rem;
  color: red;
  font-weight: bold;
  background-color: white;
  margin-top: 50px;
}
.admin {
  text-align: center;
  font-weight: bold;
  color: green;
  background-color: rgb(237, 237, 102);
}

.prod-header {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  align-items: center;
  padding: 0 10px 0px 0px;
  box-sizing: border-box;
  border: 1px solid black;
  width: 100%;
  height: 100%;
  margin: 0px auto;
  padding: 0 0rem;
  background-color: beige;
}

.grid-1 {
  grid-column-start: 1;
  font-weight: bold;
}
.grid-2 {
  grid-column-start: 2;
  font-weight: bold;
}
.grid-3 {
  grid-column-start: 3;
  font-weight: bold;
}
.grid-4 {
  grid-column-start: 4;
  font-weight: bold;
}
.grid-5 {
  grid-column-start: 5;
  font-weight: bold;
}
.grid-6 {
  grid-column-start: 6;
  font-weight: bold;
}
.grid-7 {
  grid-column-start: 7;
  font-weight: bold;
}
.grid-8 {
  grid-column-start: 8;
  font-weight: bold;
}
.grid-9 {
  grid-column-start: 9;
  font-weight: bold;
}
.grid-10 {
  grid-column-start: 10;
  font-weight: bold;
}
.grid-11 {
  grid-column-start: 11;
  font-weight: bold;
}
.grid-12 {
  grid-column-start: 12;
  font-weight: bold;
}

p.dashboard {
  font-weight: bold;
  font-size: 1rem;
  color: white;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.new-subscriber {
  cursor: pointer;
}

.animated-ellipsis {
  animation: loading 1.4s infinite ease-in-out;
}

@keyframes loading {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.animated-ellipsis:nth-child(1) {
  animation-delay: -0.32s;
}
.animated-ellipsis:nth-child(2) {
  animation-delay: -0.16s;
}
