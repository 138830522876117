.dashboard-wrapper {
  padding-top: 20px;
  height: 100%;
}

.dashboard-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}
.expired-subscription {
  background-image: url('../../../../../src/assets/hazard.png');
  background-position: center;
  background-size: auto;
  width: 40px;
  height: 40px;
  /* overflow: fit-content; */
  display: flex;
  align-content: center;
}
