html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
.mobile-title-desk {
  display: none;
}
.mobile-title {
  display: block;
  font-size: 0.7rem;
  font-weight: bold;
  text-align: left;
  margin-left: 3px;
  top: 0;
}

.admin-container {
  display: flex;
  box-sizing: content-box;
  transform: translate(0%, -3%);
  padding: 0;
  height: 100%;
}

.admin-panel {
  border: 1px solid rgb(0, 62, 0);
  display: none;
  box-sizing: border-box;
  min-width: 350px;
}

.list-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  margin: 65px auto 0px auto;
  letter-spacing: 1.5px;
  position: relative;
  width: 100%;

  /* TODO overflow-y: scroll; */
  /* overflow-y: scroll;    THIS IS NEEDED BUT WON'T WORK RIGHT...??????*/
}

@keyframes ocean {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.post {
  width: 100%;
  height: 55px;
  background-color: rgba(255, 255, 255, 0.109);
  display: grid;
  grid-template-columns: 60px 80px;
  justify-items: self-start;
  padding: 0 10px 0px 0px;
  box-sizing: border-box;
  border: 1px solid black;
}

.admin-prod-img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  object-position: center;
}

.delete-button,
.edit-button {
  width: 30px;
  height: 30px;
  object-fit: cover;
  object-position: center;
  margin: 0 5px;
}

.admin-prod-btn-cont {
  justify-self: flex-end;
  display: block;
  margin: 0px 0;
}

.grid-s1 {
  grid-column-start: 1;
  font-weight: bold;
}
.grid-s2 {
  grid-column-start: 2;
  font-weight: bold;
}
.grid-s3 {
  grid-column-start: 3;
  font-weight: bold;
}
.grid-s4 {
  grid-column-start: 4;
  font-weight: bold;
}
.grid-s5 {
  grid-column-start: 5;
  font-weight: bold;
}
.grid-s6 {
  grid-column-start: 6;
  font-weight: bold;
}
.grid-s7 {
  grid-column-start: 7;
  font-weight: bold;
}

.grid-e1 {
  grid-column-end: 1;
  font-weight: bold;
}
.grid-e2 {
  grid-column-end: 2;
  font-weight: bold;
}
.grid-e3 {
  grid-column-end: 3;
  font-weight: bold;
}
.grid-e4 {
  grid-column-end: 4;
  font-weight: bold;
}
.grid-e5 {
  grid-column-end: 5;
  font-weight: bold;
}
.grid-e6 {
  grid-column-end: 6;
  font-weight: bold;
}
.grid-e7 {
  grid-column-end: 7;
  font-weight: bold;
}

.desc-desk,
.cat-desk {
  display: none;
  white-space: nowrap;
}

.grayed-out {
  opacity: 0.5;
  pointer-events: none;
}

.mobile-dashboard-buttons {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  transform: translate(0%, -70%);
}

.small-size-inventory {
  margin-top: 20px;
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.inner-button-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-items: center;
  align-items: center;
}

.title-price-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  margin-left: 10px;
}

.loading-detail {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  left: 70px;
  top: -45px;
}
.loading-detail-2 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 325px;
}

.animated-ellipsis {
  animation: loading 1.4s infinite ease-in-out;
}

@keyframes loading {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.animated-ellipsis:nth-child(1) {
  animation-delay: -0.32s;
}
.animated-ellipsis:nth-child(2) {
  animation-delay: -0.16s;
}

/*


@media BEGIN================================


*/

/* 


768px ----------------------------------------------------------
*/

.loading-detail {
  justify-self: center;
}
@media (min-width: 768px) {
  .post {
    width: 100%;
    height: 100px;
    display: grid;
    grid-template-columns: 120px 250px 75px;
    align-items: flex-start;
    padding: 0 10px 0px 0px;
    box-sizing: border-box;
    border: 1px solid black;
    white-space: nowrap;
  }

  .admin-prod-btn-cont {
    justify-self: flex-end;
    display: block;
    margin: 16px 0;
  }

  .admin-prod-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
  }

  .list-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 35px repeat(auto-fill, 100px);
    flex-wrap: wrap;
    justify-items: flex-start;
    align-items: flex-start;
    margin: 54px auto 0 auto;
    padding: 0 0rem;
    gap: 5px;
  }

  .mobile-title-desk {
    display: block;
    max-width: 300px;
    height: 100px;
    overflow: hidden;
    white-space: normal;
  }
  .mobile-title {
    display: none;
  }
  .cat-desk {
    display: unset;
    justify-self: center;
  }
  .delete-button,
  .edit-button {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    margin: 5px 5px;
  }
  .mobile-dashboard-buttons {
    margin-top: 0;
  }

  .title-price-container {
    display: grid;
    grid-template-rows: 1.5rem 1.5rem;
    text-align: left;
  }
}
/*  END 768px----------------------------------------------------------

1024px ----------------------------------------------------------
*/
@media (min-width: 1024px) {
  .admin-container {
    grid-template-columns: 350px 1fr;
    grid-template-rows: 1fr;
  }
  .admin-panel {
    display: block;
  }
  .admin-panel-section {
    flex-wrap: wrap;
    justify-items: flex-start;
    align-items: flex-start;
    margin: 5px auto;
    padding: 0 0rem;
  }

  .menu-icon {
    display: none;
  }

  .signout-button-adapt {
    width: 100%;
    margin-top: 300px;
  }

  .new-link,
  .signout-button {
    display: flex;
    justify-content: space-between;
    font-family: 'Shadows Into Light', cursive;
    margin: 10px auto 10px auto;
    border: 1px solid rgb(0, 90, 0);
  }
  .temp-fix {
    border: 1px solid rgb(0, 90, 0);
    margin: 20px auto 2% auto;
  }

  .new-link:hover,
  .signout-button:hover {
    background-color: #ccc;
    transform: scale(1);
    background-size: 400% 400%;
    animation: shimmer 300ms ease;
  }
  .new-link:active,
  .signout-button:active {
    background-color: #acacac;
    transform: scale(1);
    transition: 250ms;
    background-size: 400% 400%;
    animation: shimmer2 250ms ease-in-out;
  }

  @keyframes shimmer2 {
    0% {
      background-position: 0% 100%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 100%;
    }
  }
  .mobile-dashboard-buttons {
    display: none;
  }

  .list-container {
    grid-template-rows: 30px repeat(auto-fill, 100px);
    height: calc(100vh - 90px);
    overflow-y: auto;
    margin: 0;
  }
  .large-size-inventory {
    display: none;
  }
}
/* END 1024px----------------------------------------------------------



1200px----------------------------------------------------------
*/
@media (min-width: 1200px) {
  /* .desc-desk; */
  .desc-desk {
    display: block;
    white-space: normal;
    overflow: hidden;
    width: 245px;
    height: 65px;
    padding-left: 30px;
    text-align: left;
  }
}
/* END 1200px----------------------------------------------------------



1400px ----------------------------------------------------------
*/
@media (min-width: 1400px) {
  .admin-container {
    grid-template-columns: 350px 1fr;
  }
  .post {
    grid-template-columns: 120px 250px 75px 200px 90px;
  }
  .desc-desk {
    width: 450px;
  }
}
/*  END 1400px----------------------------------------------------------


 1600px ----------------------------------------------------------   
*/
@media (min-width: 1600px) {
  .admin-container {
    grid-template-columns: 350px 1fr 350px;
  }
  .small-size-inventory {
    display: none;
  }
  .large-size-inventory {
    display: block;
  }
  .desc-desk {
    width: 350px;
  }
}
