.firepit {
  position: relative;
  top: 125px;
  justify-self: center;
}
.fire {
  position: relative;
  top: -100px;
  width: 60px;
  height: 60px;
}
.fire .flame {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 70%;
  border-bottom-left-radius: 70%;
  border-top-left-radius: 70%;
  transform: rotate(-45deg) skew(-10deg, -10deg);
  background-color: rgb(255, 34, 0);
  background-image: linear-gradient(to top right, rgba(255, 34, 0, 0.1), rgba(255, 106, 0, 0.3));
  z-index: 1;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-name: burn-left;
  animation-duration: 300ms;
}

.fire .flame:nth-of-type(2n) {
  animation-name: burn-right;
  animation-duration: 400ms;
}

.fire .flame:nth-of-type(2) {
  height: 80%;
  width: 80%;
  left: 10%;
  background-color: rgb(255, 106, 0);
  background-image: linear-gradient(to top right, rgba(255, 106, 0, 0.9), rgba(255, 200, 0, 0.9));
  z-index: 2;
}

.fire .flame:nth-of-type(3) {
  height: 50%;
  width: 50%;
  left: 25%;
  background-image: linear-gradient(to top right, rgb(255, 200, 0), white);
  z-index: 3;
}

.fire .flame:nth-of-type(4) {
  height: 30%;
  width: 30%;
  left: 35%;
  background-color: white;
  background-image: linear-gradient(to top right, rgba(255, 255, 255, 0.7), rgba(0, 55, 255, 0.1));
  opacity: 1;
  z-index: 4;
}
.spark {
  position: absolute;
  bottom: 46%;
  left: -130px; /* Adjusted */
  width: 3px;
  height: 3px;
  background-color: rgba(255, 106, 0, 0.4);
  animation-name: fly-up-burnout-1;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0.4s;
}
.spark:nth-of-type(2n) {
  animation-name: fly-up-burnout-2;
  animation-duration: 8.4s;
  animation-delay: 1s;
  left: -180px; /* Adjusted */
}
.spark:nth-of-type(3n) {
  animation-delay: 1.9s;
  animation-duration: 8s;
  left: -180px; /* Adjusted */
}
.spark:nth-of-type(4n) {
  animation-delay: 2.6s;
  animation-duration: 7s;
  left: -230px; /* Adjusted */
}
.spark:nth-of-type(5n) {
  animation-delay: 1.4s;
  animation-duration: 7s;
  left: -220px; /* Adjusted */
}
.spark:nth-of-type(6n) {
  animation-delay: 3.2s;
  left: -210px; /* Adjusted */
}
.spark:nth-of-type(7n) {
  animation-delay: 0.8s;
  animation-duration: 5s;
  left: -200px; /* Adjusted */
}
.spark:nth-of-type(8n) {
  animation-delay: 5s;
  left: -190px; /* Adjusted */
}
.spark:nth-of-type(9n) {
  animation-delay: 4s;
  animation-duration: 6.8s;
  left: -180px; /* Adjusted */
}
.spark:nth-of-type(10n) {
  animation-delay: 5.2s;
  left: -170px; /* Adjusted */
}
.spark:nth-of-type(11n) {
  animation-delay: 2s;
  left: -195px; /* Adjusted */
}
.spark:nth-of-type(12n) {
  animation-duration: 7.2s;
  animation-delay: 0.8s;
  left: -130px; /* Adjusted */
}
.spark:nth-of-type(13n) {
  animation-delay: 1.2s;
  animation-duration: 6.8s;
  left: -175px; /* Adjusted */
}
.spark:nth-of-type(14n) {
  animation-delay: 2.4s;
  animation-duration: 7.2s;
  left: -165px; /* Adjusted */
}
.spark:nth-of-type(15n) {
  animation-delay: 3.6s;
  animation-duration: 6.8s;
  left: -185px; /* Adjusted */
}
.spark:nth-of-type(16n) {
  position: relative;
  animation-delay: 4.8s;
  animation-duration: 7.2s;
  left: -200px; /* Adjusted */
  top: 100px;
}

@keyframes burn-left {
  0%,
  100% {
    transform: rotate(-45deg) skew(-10deg, -10deg) scale(1);
  }
  30%,
  60% {
    transform: rotate(-44deg) skew(-12deg, -12deg) scale(1.01);
  }
}

@keyframes burn-right {
  0%,
  100% {
    transform: rotate(-45deg) skew(-10deg, -10deg) scale(1);
  }
  30%,
  60% {
    transform: rotate(-46deg) skew(-6deg, -6deg) scale(1.01);
  }
}

@keyframes fly-up-burnout-1 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  33% {
    transform: translate(12px, -70px);
  }
  66% {
    transform: translate(0, -140px);
    opacity: 0.6;
  }
  100% {
    transform: translate(6px, -200px);
    opacity: 0;
  }
}

@keyframes fly-up-burnout-2 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: translate(-10px, -80px);
  }
  80% {
    transform: translate(-4px, -140px);
    opacity: 0.6;
  }
  100% {
    transform: translate(-6px, -160px);
    opacity: 0;
  }
}

/* 

beginning of media rules  =======================================

 */

@media (min-width: 768px) {
  .firepit {
    position: relative;
    left: 12%;
    top: 175px;

    /* 
    justify-content: center;
    */
    display: flex;
    /* align-items: center; */
    /* justify-self: center; */
  }
  .fire {
    position: relative;
    /* top: -100px;*/
    left: -400px;
    width: 60px;
    height: 60px;
  }
  .fire .flame {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 70%;
    border-bottom-left-radius: 70%;
    border-top-left-radius: 70%;
    transform: rotate(-45deg) skew(-10deg, -10deg);
    background-color: rgb(255, 34, 0);
    background-image: linear-gradient(to top right, rgba(255, 34, 0, 0.1), rgba(255, 106, 0, 0.3));
    z-index: 1;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-name: burn-left;
    animation-duration: 300ms;
  }

  .fire .flame:nth-of-type(2n) {
    animation-name: burn-right;
    animation-duration: 400ms;
  }

  .fire .flame:nth-of-type(2) {
    height: 80%;
    width: 80%;
    left: 10%;
    background-color: rgb(255, 106, 0);
    background-image: linear-gradient(to top right, rgba(255, 106, 0, 0.9), rgba(255, 200, 0, 0.9));
    z-index: 2;
  }

  .fire .flame:nth-of-type(3) {
    height: 50%;
    width: 50%;
    left: 25%;
    background-image: linear-gradient(to top right, rgb(255, 200, 0), white);
    z-index: 3;
  }

  .fire .flame:nth-of-type(4) {
    height: 30%;
    width: 30%;
    left: 35%;
    background-color: white;
    background-image: linear-gradient(
      to top right,
      rgba(255, 255, 255, 0.7),
      rgba(0, 55, 255, 0.1)
    );
    opacity: 1;
    z-index: 4;
  }

  .spark {
    position: absolute;
    bottom: 46%;
    left: 15%;
    width: 3px;
    height: 3px;
    background-color: rgba(255, 106, 0, 0.4);
    animation-name: fly-up-burnout-1;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 0.4s;
  }
  .spark:nth-of-type(2n) {
    animation-name: fly-up-burnout-2;
    animation-duration: 8.4s;
    animation-delay: 1s;
    left: 60px;
  }
  .spark:nth-of-type(3n) {
    animation-delay: 1.9s;
    animation-duration: 8s;
    left: 50px;
  }
  .spark:nth-of-type(4n) {
    animation-delay: 2.6s;
    animation-duration: 7s;
    left: 100px;
  }
  .spark:nth-of-type(5n) {
    animation-delay: 1.4s;
    animation-duration: 7s;
    left: 90px;
  }
  .spark:nth-of-type(6n) {
    animation-delay: 3.2s;
    left: 80px;
  }
  .spark:nth-of-type(7n) {
    animation-delay: 0.8s;
    animation-duration: 5s;
    left: 70px;
  }
  .spark:nth-of-type(8n) {
    animation-delay: 5s;
    left: 60px;
  }
  .spark:nth-of-type(9n) {
    animation-delay: 4s;
    animation-duration: 6.8s;
    left: 50px;
  }
  .spark:nth-of-type(10n) {
    animation-delay: 5.2s;
    left: 40px;
  }
  .spark:nth-of-type(11n) {
    animation-delay: 2s;
    left: 65px;
  }
  .spark:nth-of-type(12n) {
    animation-duration: 7.2s;
    animation-delay: 0.8s;
  }
  .spark:nth-of-type(13n) {
    animation-delay: 1.2s;
    animation-duration: 6.8s;
    left: 45px;
  }
  .spark:nth-of-type(14n) {
    animation-delay: 2.4s;
    animation-duration: 7.2s;
    left: 35px;
  }
  .spark:nth-of-type(15n) {
    animation-delay: 3.6s;
    animation-duration: 6.8s;
    left: 55px;
  }
  .spark:nth-of-type(16n) {
    position: relative;
    animation-delay: 4.8s;
    animation-duration: 7.2s;
    left: 70px;
    top: 100px;
  }

  @keyframes burn-left {
    0%,
    100% {
      transform: rotate(-45deg) skew(-10deg, -10deg) scale(1);
    }
    30%,
    60% {
      transform: rotate(-44deg) skew(-12deg, -12deg) scale(1.01);
    }
  }

  @keyframes burn-right {
    0%,
    100% {
      transform: rotate(-45deg) skew(-10deg, -10deg) scale(1);
    }
    30%,
    60% {
      transform: rotate(-46deg) skew(-6deg, -6deg) scale(1.01);
    }
  }

  @keyframes fly-up-burnout-1 {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    33% {
      transform: translate(12px, -70px);
    }
    66% {
      transform: translate(0, -140px);
      opacity: 0.6;
    }
    100% {
      transform: translate(6px, -200px);
      opacity: 0;
    }
  }

  @keyframes fly-up-burnout-2 {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    50% {
      transform: translate(-10px, -80px);
    }
    80% {
      transform: translate(-4px, -140px);
      opacity: 0.6;
    }
    100% {
      transform: translate(-6px, -160px);
      opacity: 0;
    }
  }
}
