.calendar-day-date {
  background-color: #787878;
  border-right: 1px solid #ccc;
  padding-right: 10px;
  width: 20%;
}

.calendar-day-total {
  font-weight: bold;
}

.totals-display {
  font-weight: bold;
}

.selected-products {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  margin: 0 0;
}

.list-of-day-products {
  /* background-color: #f5f5f5; */
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

.list-of-day-products .product-title {
  font-weight: bold;
}
.css-19kzrtu {
  padding: 0px;
}
.calendar-container {
  display: flex;
  flex-direction: column;
  grid-template-rows: repeat(auto-fill, minmax(50px, auto));
  flex-wrap: wrap;
  justify-items: flex-start;
  align-items: flex-start;
  margin: auto 0 auto;
  padding: 0 0rem;
  letter-spacing: 1.5px;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
