body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #094a00 #848e76;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #f8fff936;
}

*::-webkit-scrollbar-track:hover {
  background-color: #f8fff963;
}

*::-webkit-scrollbar-track:active {
  background-color: #cdd1ce80;
}

*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #e4e4e4;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #595e58;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #464b46;
}
