.auth-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1;
  padding-top: 80px;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 400px;
  margin: 0 auto;
  border-radius: 4px;
}

.form-container-paper {
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 320px;
  height: 400px;
  margin: 0 auto;
  padding: 16px;
}

.toast {
  position: fixed;
  top: -125px;
  left: 50%;
  transform: translateX(-50%);
  background-color: red;
  color: white;
  padding: 10px;
  border-radius: 4px;
  display: none;
  z-index: 1000;
  width: 100%;
}

.toast.show {
  display: block;
}

.sign-up-toast {
  position: relative;
  margin-top: 200px;
  padding: 10px;
  background-color: #008425;
  color: white;
  border-radius: 5px;
  z-index: 1000;
}

.animated-ellipsis {
  animation: loading 1.4s infinite ease-in-out;
}

@keyframes loading {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.animated-ellipsis:nth-child(1) {
  animation-delay: -0.32s;
}
.animated-ellipsis:nth-child(2) {
  animation-delay: -0.16s;
}
