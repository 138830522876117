/* An animated flame using only CSS3 animations and box-shadow. */
.container {
  margin: 75px auto;
  width: 60px;
  height: 60px;
  position: relative;
  transform-origin: center bottom;
  animation-name: brilla-fire;
  animation-duration: 3ms;
  animation-delay: 200ms;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.flame {
  bottom: 0;
  position: absolute;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  transform: rotate(-45deg) scale(1.5, 1.5);
}

.yellow {
  left: 15px;
  width: 30px;
  top: 20px;
  height: 30px;
  background: gold;
  /* box-shadow: 0px 0px 9px 4px gold; */
  transform: scale(0.9, 2.5) rotate(-69deg);
  animation: brilla-fire 5.5s alternate infinite;
  /* animation: flicker 1s infinite; */
  z-index: 3;
}

.orange {
  left: -10px;
  top: -10px;

  width: 60px;
  height: 60px;
  background: orange;
  /* box-shadow: 0px 0px 9px 6px orange; */
  transform: scale(0.9, 1.9) rotate(-80deg);
  animation: brilla-fire 5.5s alternate infinite;
  /* animation: flicker 1s infinite; */
  z-index: 2;
}
.orange2 {
  left: 0px;
  top: -20px;
  width: 60px;
  height: 60px;
  background: orange;
  /* box-shadow: 0px 0px 9px 6px orange; */
  transform: scale(0.9, 1.9) rotate(-23deg);
  animation: brilla-fire 4.5s alternate infinite;
  /* animation: flicker 1s infinite; */
  z-index: 2;
}
.orange3 {
  left: 20px;
  top: -10px;
  width: 60px;
  height: 60px;
  background: orange;
  /* box-shadow: 0px 0px 9px 6px orange; */
  transform: scale(0.7, 1.7) rotate(-20deg);
  animation: brilla-fire 4.2s alternate infinite;
  /* animation: flicker 1s infinite; */
  z-index: 2;
}

.red {
  left: -15px;
  width: 80px;
  top: -35px;
  height: 80px;
  background: OrangeRed;
  /* box-shadow: 0px 0px 25px 6px OrangeRed; */
  transform: scale(1.4, 1.8) rotate(-75deg);
  animation: brilla-fire 6s alternate infinite;
  /* animation: flicker 1s infinite;*/
  z-index: 1;
}
.red2 {
  left: -5px;
  top: -50px;
  width: 80px;
  height: 80px;
  background: OrangeRed;
  /* box-shadow: 0px 0px 25px 6px OrangeRed; */
  transform: scale(0.9, 2.2) rotate(-55deg);
  animation: brilla-fire 5.5s alternate infinite;
  /* animation: flicker 1s infinite; */
  z-index: 1;
}
.red3 {
  left: 10px;
  width: 80px;
  height: 80px;
  top: -30px;
  background: OrangeRed;
  /* box-shadow: 0px 0px 25px 6px OrangeRed; */
  transform: scale(1.1, 1.6) rotate(-15deg);
  animation: brilla-fire 4.5s alternate infinite;
  /* animation: flicker 1s infinite; */
  z-index: 1;
  bottom: 20px;
}

.red4 {
  left: 0px;
  width: 80px;
  height: 80px;
  top: -30px;
  background: OrangeRed;
  /* box-shadow: 0px 0px 25px 6px OrangeRed; */
  transform: scale(1.2, 1.7) rotate(20deg);
  animation: brilla-fire 3.6s alternate infinite;
  /* animation: flicker 1s infinite; */
  z-index: 1;
  bottom: 20px;
}
.red5 {
  left: 0px;
  width: 80px;
  height: 80px;
  top: -30px;
  background: OrangeRed;
  /* box-shadow: 0px 0px 25px 6px OrangeRed; */
  transform: scale(1.5, 1.7) rotate(-110deg);
  animation: brilla-fire 2.5s alternate infinite;
  /* animation: flicker 1s infinite; */
  z-index: 1;
  bottom: 20px;
}

.white {
  left: 15px;
  bottom: -4px;
  width: 30px;
  height: 30px;
  background: white;
  box-shadow: 0px 0px 9px 4px white;
  z-index: 5;
}

.circle {
  border-radius: 50%;
  position: absolute;
}

.blfue {
  width: 10px;
  height: 10px;
  left: 25px;
  bottom: -25px;
  background: SlateBlue;
  box-shadow: 0px 0px 30px 30px SlateBlue;
  z-index: 5;
  top: 100px;
}

.blacck {
  width: 40px;
  height: 40px;
  left: 10px;
  bottom: -60px;
  background: #121212;
  box-shadow: 0px 0px 35px 55px #121212;
  z-index: 6;
  top: 120px;
}

@keyframes flicker {
  0% {
    transform: rotate(-1deg);
  }
  20% {
    transform: rotate(1deg);
  }
  40% {
    transform: rotate(-1deg);
  }
  60% {
    transform: rotate(1deg) scaleY(1.04);
  }
  80% {
    transform: rotate(-2deg) scaleY(0.92);
  }
  100% {
    transform: rotate(1deg);
  }
}

/* complex fire */

.fire {
  position: absolute;
  /* display: grid; */
  /* margin: 'auto'; */
  z-index: 39;
  width: 100%;
  /* margin-left: -1px; */
  /* left: 50%; */
  /* top: 250px; */
  -webkit-transition: all 1200ms linear;
  transition: all 1200ms linear;
  /* border: 2px solid red; */
}
.fire span {
  display: block;
  position: absolute;
  /* margin-left: -20px; */
  margin: auto;

  height: 0px;
  width: 0px;
  border: 30px solid #febd08;
  border-radius: 50%;
  border-top-left-radius: 0;
  left: -9px;
  box-shadow: 0 0 10px 5px rgba(244, 110, 28, 0.8), 0 0 20px 10px rgba(244, 110, 28, 0.6),
    0 0 30px 15px rgba(244, 110, 28, 0.3);
  transform: scale(2.3, 3.3) rotate(45deg);
  /* animation: brilla-fire 2.5s alternate infinite; */
  z-index: 9;
  -webkit-transition: all 1200ms linear;
  transition: all 1200ms linear;
}
.fire span:after {
  display: block;
  position: absolute;
  bottom: -30px;
  content: '';
  margin: auto;
  height: 40px;
  width: 12px;
  background-color: rgba(244, 110, 28, 0.7);
  border-radius: 80px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 0 20px 10px rgba(244, 110, 28, 0.7);
  left: -9px;
  opacity: 0.8;
  transform: rotate(-50deg);
}
.fire span:nth-child(2) {
  left: -55px;
  top: -50px;
  margin: auto;
  transform: scale(1.8, 3.1) rotate(15deg);
  z-index: 8;
  /* animation: brilla-fire 1.5s alternate infinite; */
}
.fire span:nth-child(3) {
  left: 35px;
  top: -120px;
  margin: auto;
  transform: scale(1.8, 3.1) rotate(80deg);
  z-index: 8;
  /* animation: brilla-fire 2s alternate infinite; */
}
@keyframes brilla-fire {
  0%,
  100% {
    box-shadow: 0 0 10px 5px rgba(244, 110, 28, 0.3), 0 0 10px 10px rgba(244, 110, 28, 0.3),
      0 0 10px 15px rgba(244, 110, 28, 0.3);
  }

  50% {
    box-shadow: 0 0 14px 7px rgba(244, 110, 28, 0.3), 0 0 28px 14px rgba(244, 110, 28, 0.3),
      0 0 42px 21px rgba(244, 110, 28, 0.3);
  }
}
/* 

media rules begin ========================================================

*/

@media (min-width: 768px) {
  /* An animated flame using only CSS3 animations and box-shadow. */
  .container {
    margin: 75px auto;
    width: 60px;
    height: 60px;
    position: relative;
    transform-origin: center bottom;
    animation-name: brilla-fire;
    animation-duration: 3ms;
    animation-delay: 200ms;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .flame {
    bottom: 0;
    position: absolute;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    transform: rotate(-45deg) scale(1.5, 1.5);
  }

  .yellow {
    left: 15px;
    width: 30px;
    top: 20px;
    height: 30px;
    background: gold;
    /* box-shadow: 0px 0px 9px 4px gold; */
    transform: scale(0.9, 2.5) rotate(-69deg);
    animation: brilla-fire 5.5s alternate infinite;
    /* animation: flicker 1s infinite; */
    z-index: 3;
  }

  .orange {
    left: -10px;
    top: -10px;

    width: 60px;
    height: 60px;
    background: orange;
    /* box-shadow: 0px 0px 9px 6px orange; */
    transform: scale(0.9, 1.9) rotate(-80deg);
    animation: brilla-fire 5.5s alternate infinite;
    /* animation: flicker 1s infinite; */
    z-index: 2;
  }
  .orange2 {
    left: 0px;
    top: -20px;
    width: 60px;
    height: 60px;
    background: orange;
    /* box-shadow: 0px 0px 9px 6px orange; */
    transform: scale(0.9, 1.9) rotate(-23deg);
    animation: brilla-fire 4.5s alternate infinite;
    /* animation: flicker 1s infinite; */
    z-index: 2;
  }
  .orange3 {
    left: 20px;
    top: -10px;
    width: 60px;
    height: 60px;
    background: orange;
    /* box-shadow: 0px 0px 9px 6px orange; */
    transform: scale(0.7, 1.7) rotate(-20deg);
    animation: brilla-fire 4.2s alternate infinite;
    /* animation: flicker 1s infinite; */
    z-index: 2;
  }

  .red {
    left: -15px;
    width: 80px;
    top: -35px;
    height: 80px;
    background: OrangeRed;
    /* box-shadow: 0px 0px 25px 6px OrangeRed; */
    transform: scale(1.4, 1.8) rotate(-75deg);
    animation: brilla-fire 6s alternate infinite;
    /* animation: flicker 1s infinite;*/
    z-index: 1;
  }
  .red2 {
    left: -5px;
    top: -50px;
    width: 80px;
    height: 80px;
    background: OrangeRed;
    /* box-shadow: 0px 0px 25px 6px OrangeRed; */
    transform: scale(0.9, 2.2) rotate(-55deg);
    animation: brilla-fire 5.5s alternate infinite;
    /* animation: flicker 1s infinite; */
    z-index: 1;
  }
  .red3 {
    left: 10px;
    width: 80px;
    height: 80px;
    top: -30px;
    background: OrangeRed;
    /* box-shadow: 0px 0px 25px 6px OrangeRed; */
    transform: scale(1.1, 1.6) rotate(-15deg);
    animation: brilla-fire 4.5s alternate infinite;
    /* animation: flicker 1s infinite; */
    z-index: 1;
    bottom: 20px;
  }

  .red4 {
    left: 0px;
    width: 80px;
    height: 80px;
    top: -30px;
    background: OrangeRed;
    /* box-shadow: 0px 0px 25px 6px OrangeRed; */
    transform: scale(1.2, 1.7) rotate(20deg);
    animation: brilla-fire 3.6s alternate infinite;
    /* animation: flicker 1s infinite; */
    z-index: 1;
    bottom: 20px;
  }
  .red5 {
    left: 0px;
    width: 80px;
    height: 80px;
    top: -30px;
    background: OrangeRed;
    /* box-shadow: 0px 0px 25px 6px OrangeRed; */
    transform: scale(1.5, 1.7) rotate(-110deg);
    animation: brilla-fire 2.5s alternate infinite;
    /* animation: flicker 1s infinite; */
    z-index: 1;
    bottom: 20px;
  }

  .white {
    left: 15px;
    bottom: -4px;
    width: 30px;
    height: 30px;
    background: white;
    box-shadow: 0px 0px 9px 4px white;
    z-index: 5;
  }

  .circle {
    border-radius: 50%;
    position: absolute;
  }

  .blue {
    width: 10px;
    height: 10px;
    left: 25px;
    bottom: -25px;
    background: SlateBlue;
    box-shadow: 0px 0px 30px 30px SlateBlue;
    z-index: 5;
    top: 100px;
  }

  .black {
    width: 40px;
    height: 40px;
    left: 10px;
    bottom: -60px;
    background: #121212;
    box-shadow: 0px 0px 35px 55px #121212;
    z-index: 6;
    top: 120px;
  }

  @keyframes flicker {
    0% {
      transform: rotate(-1deg);
    }
    20% {
      transform: rotate(1deg);
    }
    40% {
      transform: rotate(-1deg);
    }
    60% {
      transform: rotate(1deg) scaleY(1.04);
    }
    80% {
      transform: rotate(-2deg) scaleY(0.92);
    }
    100% {
      transform: rotate(1deg);
    }
  }

  /* complex fire */

  .fire {
    position: absolute;
    /* display: grid; */
    /* margin: 'auto'; */
    z-index: 39;
    width: 100%;
    /* margin-left: -1px; */
    /* left: 50%; */
    /* top: 250px; */
    -webkit-transition: all 1200ms linear;
    transition: all 1200ms linear;
    /* border: 2px solid red; */
  }
  .fire span {
    display: block;
    position: absolute;
    /* margin-left: -20px; */
    margin: auto;

    height: 0px;
    width: 0px;
    border: 30px solid #febd08;
    border-radius: 50%;
    border-top-left-radius: 0;
    left: -9px;
    box-shadow: 0 0 10px 5px rgba(244, 110, 28, 0.8), 0 0 20px 10px rgba(244, 110, 28, 0.6),
      0 0 30px 15px rgba(244, 110, 28, 0.3);
    transform: scale(2.3, 3.3) rotate(45deg);
    /* animation: brilla-fire 2.5s alternate infinite; */
    z-index: 9;
    -webkit-transition: all 1200ms linear;
    transition: all 1200ms linear;
  }
  .fire span:after {
    display: block;
    position: absolute;
    bottom: -30px;
    content: '';
    margin: auto;
    height: 40px;
    width: 12px;
    background-color: rgba(244, 110, 28, 0.7);
    border-radius: 80px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 0 20px 10px rgba(244, 110, 28, 0.7);
    left: -9px;
    opacity: 0.8;
    transform: rotate(-50deg);
  }
  .fire span:nth-child(2) {
    left: -55px;
    top: -50px;
    margin: auto;
    transform: scale(1.8, 3.1) rotate(15deg);
    z-index: 8;
    /* animation: brilla-fire 1.5s alternate infinite; */
  }
  .fire span:nth-child(3) {
    left: 35px;
    top: -120px;
    margin: auto;
    transform: scale(1.8, 3.1) rotate(80deg);
    z-index: 8;
    /* animation: brilla-fire 2s alternate infinite; */
  }
  @keyframes brilla-fire {
    0%,
    100% {
      box-shadow: 0 0 10px 5px rgba(244, 110, 28, 0.3), 0 0 10px 10px rgba(244, 110, 28, 0.3),
        0 0 10px 15px rgba(244, 110, 28, 0.3);
    }

    50% {
      box-shadow: 0 0 14px 7px rgba(244, 110, 28, 0.3), 0 0 28px 14px rgba(244, 110, 28, 0.3),
        0 0 42px 21px rgba(244, 110, 28, 0.3);
    }
  }
}
